/* Built In Imports */
/* External Imports */

/* Internal Imports */
/* Components */
import HomeTopBanner from '@lb/Sections/lbHomePage/HomeTopBanner';

/**
 *
 * @param {object} section
 * @returns
 */

const HomeBanner = ({ section, region, lang, headerMenuData, topMenuData }) => {
  return (
    <>
      <HomeTopBanner
        region={region}
        lang={lang}
        section={section}
        sliderContent={section?.sectionContent[0]}
        title={section?.sectionContent[1]}
        description={section?.sectionContent[2]}
        images={section?.sectionContent[3]}
        video={section?.sectionContent[4]}
        mobileVideo={section?.sectionContent[5]}
        headerMenuData={headerMenuData}
        topMenuData={topMenuData}
      />
    </>
  );
};

export default HomeBanner;
