/* Built In Imports */
import React, { useState } from 'react';

/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */

/* Styles */
import SliderCard1 from '@lb/Sections/lbHomePage/BBSlider/SliderCard1';
import SliderCard2 from '@lb/Sections/lbHomePage/BBSlider/SliderCard2';
import SliderCard3 from '@lb/Sections/lbHomePage/BBSlider/SliderCard3';
/* Services */

/**
 *
 * @param {object} section
 * @returns
 */

const BringBhairaviHomeSlider = ({ section, region, lang, pType }) => {
  const [bgIndex, setBgIndex] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: index => setBgIndex(index),
    appendDots: dots => (
      <Box
        position="absolute"
        // style={{
        // }}
        // bottom="50%"
        display="flex"
        flexDir="row"
        alignItems="center"
        top={{ base: '20px', md: '20px', lg: '25px' }}
        right={{ base: '16px', md: '16px', lg: '0' }}
        // transform="translate(-50%,-50%)"
        left={{
          base: '16px',
          sm: '16px',
          md: '16px',
          lg: '70px',
        }}
        w={{ base: '280px', md: '450px' }}
        maxH="60px"
        bottom="95%"
        className="homeBannerSlick"
      >
        <ul style={{ margin: '0px' }} className="homeBannerSlickDot">
          {' '}
          {dots}{' '}
        </ul>
      </Box>
    ),
    customPaging: () => (
      <Box
        
          color='transparent'
          borderRadius='8px'
        width={{ base: '71.77px', md: '130px' }}
        height={{ base: '5.1px', md: '5px' }}
        className="dot"
      ></Box>
    ),
  };
  const tabs = section?.sectionContent[0].tabContent;

  return (
    <Box
      h={{
        base: pType == 'home' && section.viewH ? '100svh' : 'auto',
        md: 'auto',
      }}
    >
      <Box w="100%" maxW={{ base: 'auto', lg: 1330 }} mx="auto" pos="relative">
        <Flex
          pos="absolute"
          top={{
            base:
              pType == 'home' && section.viewH
                ? 'calc(100% - 37px)'
                : 'calc(100% - 37px)',
            md: '0',
          }}
          right="0"
          bottom={0}
          height={{ base: '37px', md: '100%' }}
          w={{ base: '100%', md: '25%' }}
          bgImage={`url(${
            tabs[bgIndex]?.content[4]?.mobileImage?.url ||
            tabs[bgIndex]?.content[4]?.image?.url ||
            tabs[bgIndex]?.content[4]?.tabletImage?.url
          })`}
          bgRepeat="no-repeat"
          bgSize="cover"
          transition="all 0.2s linear"
          zIndex={0}
        ></Flex>

        <Box
          // pos="absolute"
          className="BringBhairaviHomeSlider"
          h={{ base: '100%', md: '100vh', xl: '100%' }}
        >
          <Slider {...settings}>
            {section?.sectionContent[0]?.tabContent?.map((tab, index) => {
              if (index <= 3) {
                return (
                  <Box key={nanoid()} height="auto">
                    {index === 0 && (
                      <SliderCard1
                        tab={tab}
                        region={region}
                        lang={lang}
                        pType={pType}
                        section={section}
                      />
                    )}
                    {index === 1 && (
                      <SliderCard2
                        tab={tab}
                        region={region}
                        lang={lang}
                        pType={pType}
                        section={section}
                      />
                    )}
                    {index === 2 && (
                      <SliderCard3
                        tab={tab}
                        region={region}
                        lang={lang}
                        pType={pType}
                        section={section}
                      />
                    )}
                    {index === 3 && (
                      <SliderCard3
                        tab={tab}
                        region={region}
                        lang={lang}
                        pType={pType}
                        section={section}
                      />
                    )}
                  </Box>
                );
              }
              return null;
            })}
          </Slider>
        </Box>
        <style>
          {`
          .BringBhairaviHomeSlider .slick-dots li div{
            background: rgba(190, 190, 190, 0.79);
          }
        .homeBannerSlickDot li{
          width:auto !important;
          
        }
        .homeBannerSlickDot{
          width:auto;
        }
          .BringBhairaviHomeSlider  .slick-dots .slick-active div{
            position:relative;
            box-sizing:border-box;
            background:#F6A15E !important;
          }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default BringBhairaviHomeSlider;
