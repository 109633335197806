/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LingaBhairaviBlogHomeFacadePlaceholder from '@lb/components/Filter/LingaBhairaviBlogHomeFacadePlaceholder';
import LingaBhairaviFilterBlog from '@lb/components/Filter/SearchFilterBlog';
import SingleLineText from '@lb/components/Text/SingleLineText';

/* Styles */
/* Services */

const BlogHeader = ({ section, region, lang }) => {
  return (
    <Box>
      <SingleLineText sectionContent={section?.sectionContent[0]} tag="h1" />
      <SingleLineText sectionContent={section?.sectionContent[1]} />
      <LingaBhairaviFilterBlog
        sectionContent={section?.sectionContent[2]}
        region={region}
        lang={lang}
      />
      <LingaBhairaviBlogHomeFacadePlaceholder
        sectionContent={section?.sectionContent[3]}
        region={region}
        language={lang}
        align="start"
        mt="-51.5px"
        border="#FFCB66"
      />
    </Box>
  );
};

export default BlogHeader;
