/* Built In Imports */
import React from 'react';
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
/* Internal Imports */
/* Components */
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */
/* Services */

const HomeFullPageCard = ({ section }) => {
  const cardData = section?.sectionContent;
  return (
    <Box
      h={{ base: 'auto', md: '100vh' }}
      mx="auto"
      position="relative"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgImage={`url(${config.imgPath}/d/46272/1681226550-group_10406_720.jpg)`}
    >
      {/* <Box
        pos="absolute"
        bottom={0}
        left={0}
        w="20%"
        top="0"
        zIndex={0}
        h="100%"
        className="left"
        bg="rgba(255, 255, 255, 0.6)"
        display={{ base: 'none', md: 'none', lg: 'block' }}
      ></Box>
      <Box
        pos="absolute"
        className="bottom"
        bottom={0}
        left={0}
        right={0}
        w="100%"
        h="104px"
        zIndex={0}
        bg="#fff"
        display={{ base: 'none', md: 'none', lg: 'block' }}
      ></Box> */}
      <Box maxW="1100px" width="100%" mx="auto">
        <Box w="100%" h="100%" pos="relative" zIndex={1}>
          <Flex
            bgImage={{
              base: `url(${cardData[2]?.mobileImage?.url})`,
              lg: `url(${cardData[2]?.image?.url})`,
            }}
            bgRepeat="no-repeat"
            bgSize={{
              base: '100% 385px',
              sm: '70% 385px',
              md: '70%',
              lg: '53% 100%',
            }}
            alignItems={{ base: 'center', md: 'center', lg: 'flex-end' }}
            justifyContent={{ base: 'center', md: 'center', lg: 'flex-end' }}
            py={{ base: '0', md: '37px', lg: '37px' }}
            px={{ base: '23px', sm: '32px', md: '40px', lg: '0' }}
            // pt={{ base: '70%', md: '70%', lg: '37px' }}
            backgroundPosition={{
              base: '0 40px',
              sm: 'center 100px',
              md: 'center 0px',
              lg: 'left',
            }}
          >
            <Flex
              flexDir="column"
              w={{ base: '100%', sm: '60%', md: '60%' }}
              maxW={{ base: 'auto', md: '567px' }}
              bg="white"
              alignItems="flex-start"
              mt={{ base: '385px', sm: '385px', md: '385px', lg: '0' }}
              p={{ base: '57px 34px', md: '5%' }}
            >
              <Text
                as="h3"
                fontSize={{ base: '32px', lg: 'h3' }}
                fontFamily="FedraSerifDisplay"
                fontWeight="600"
                lineHeight={{ base: '39px', lg: '73px' }}
                color="headingText.dark"
                maxW={456}
              >
                {cardData[0]?.text}
              </Text>
              <Text
                color="bodyText.color2"
                fontSize={{ base: 'body2', lg: 'body1' }}
                maxW={456}
                lineHeight={{ base: '23px', lg: '28.44px' }}
              >
                {cardData[1]?.text}
              </Text>
              <Box
                mt={{ base: '10px', lg: '24px' }}
                w="auto"
                mx={{ base: 'auto', lg: '0' }}
              >
                <SimpleButton style={cardData[3]} />
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeFullPageCard;
