/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
import styles from '@lb/components/Sliders/TestimonialsGroup.module.css';
/* Services */

/**
 *
 * @param testimonial
 */

const SliderCard = ({ testimonial, ptype }) => {
  return (
    <>
      <Box>
        <Flex
          flexDir={{ base: 'column', md: 'row', lg: 'row' }}
          flexWrap="wrap"
        >
          <Box textAlign="left">
            <LazyLoadImageComponent
              src={
                testimonial?.photo?.url ||
                `${config.staticPath}/d/46272/1677273937-devi-website_illustrations-4-2.png`
              }
              // maxH={{ base: '127px', md: '272px' }}
              height={{ base: '127px', md: '127px', lg: '272px' }}
              maxW={{ base: '127px', md: '127px', lg: '272px' }}
              marginRight="25px"
            />
          </Box>
          <Text
            maxW={{ base: '250px', sm: '350px', md: '394px' }}
            ml={0}
            fontSize={{ base: 'body3', md: 'body2' }}
            textAlign="left"
            display="inline"
          >
            <Box textAlign="left">
              <StructuredTextParser
                display="inline"
                str={render(testimonial.testimonialTitle)}
                // className="TestimonialGroup"
                sectionContent={testimonial.testimonialTitle}
                px="0"
                color="#fff"
                fontWeight="350"
                textAlign="left"
                fontFamily="FedraSerifAStdBook"
                fontSize={{ base: '20px', md: '32px' }}
                // lineHeight="1.2em"
                lineHeight={{ base: '20px', md: '37px' }}
                className={styles.testimonial}
              />
              &nbsp;
              <StructuredTextParser
                display="inline"
                fontFamily="FedraSerifAStdBook"
                className={styles.testimonialGroup}
                str={render(testimonial.testimonialText)}
                sectionContent={testimonial.testimonialText}
                px="0"
                color="#ffffff"
                fontWeight="350"
                textAlign="left"
              />
            </Box>
            {testimonial.userName && (
              <Box textAlign="left" mt="28px">
                <Text
                  fontWeight="500"
                  lineHeight="25px"
                  fontFamily="FedraSerifAStdBook"
                  color="common.white"
                  mt={{ base: '18px', md: '30px' }}
                  textTransform="uppercase"
                  fontSize={{ base: '14px', md: '18px' }}
                >
                  {' '}
                  - {testimonial.userName}
                </Text>
              </Box>
            )}
          </Text>
        </Flex>
      </Box>
    </>
  );
};
export { SliderCard };
