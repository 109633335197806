/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import YouTube from 'react-youtube';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';
import { getEmbedUrl, refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
import styles from '@lb/components/Embeds/RitualEmbed.module.css';

/**
 *
 * @param {object} DivineFeminine
 * @returns
 */

const DivineFeminine = ({ section, region, lang }) => {
  const title = section?.sectionContent[0];
  const embedContent = section?.sectionContent[1];
  const description = section?.sectionContent[2];
  const link = section?.sectionContent[3];
  const [opts, setOpts] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setOpts({
      height: document.getElementById('msr-vid')?.clientHeight,
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global.window]);

  const onReady = event => {
    event.target.playVideo();
  };

  if (embedContent?.image?.url) {
    return (
      <Box
        className="HomeFlexVideo"
        p="0"
        m="0"
        pos="relative"
        w="100%"
        mx="auto"
        display="flex"
        alignItems="center"
        h={section.viewH ? '100vh' : 'auto'}
      >
        <Box
          position="absolute"
          bottom={{ base: '50%', md: '0' }}
          top={{ base: '0', md: 'auto' }}
          left="0"
          zIndex={0}
          right="0"
          height={{ base: '50%', md: '147px' }}
          bg="linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 100%)"
          transform={{ base: 'none', md: 'matrix(1, 0, 0, -1, 0, 0)' }}
        ></Box>
        <Box
          // bg="linear-gradient(180deg, #D5CAAE 0%, rgba(217, 217, 217, 0) 100%)"
          className="videoBg"
          maxW="1148px"
          w="100%"
          mx="auto"
          py={{ base: '50px', md: '75px', lg: '101px' }}
          px={{ base: '16px', md: '16px', lg: '0' }}
        >
          <Box
            backgroundSize={{
              base: 'min(100%, 100%)',
              sm: '100% 53%',
              md: '100% 60%',
              lg: '50% 90%',
              xl: '50% 95%',
            }}
            h={{ base: 'auto', lg: '547px' }}
            bgImage={`url(${config.staticPath}/d/46272/1685004422-image-62.jpg)`}
            bgRepeat="no-repeat"
            p={{ base: '0', md: '39px 36px' }}
            pos="relative"
            zIndex={2}
          >
            <Flex
              bg={{
                base: 'none',
                md: 'none',
                lg: 'linear-gradient(180deg, rgba(213, 202, 174, 0.6) 0%, rgba(217, 217, 217, 0) 100%)',
              }}
              w="100%"
              h="auto"
              flexWrap={{ base: 'wrap', lg: 'nowrap' }}
              flexDir={{ base: 'column', md: 'row' }}
              // p={{ base: '0', md: '18px 35px' }}
              position="relative"
              alignItems="center"
              justifyContent="center"
              gridGap={{ base: '0', md: '40px', xl: '80px' }}
            >
              <Box
                //   bg="red"
                w={{ base: '100%', md: '100%', lg: '50%' }}
                // bg="url(https://images.pexels.com/photos/15851469/pexels-photo-15851469.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load), gray"
                // minH={{ base: '', md: '', lg: '' }}
                maxH={{ base: '290px', md: '433px', lg: '433px' }}
                h="auto"
                pos="relative"
                alignSelf="flex-start"
                p={{ base: '8px 9px', md: '26px 19px', lg: 0 }}
                zIndex="1"
                // bg="yellow"
                // boxShadow={{
                //   base: 'none',
                //   md: 'none',
                //   lg: '0px 3.63095px 18.1548px 16.3393px rgba(0, 0, 0, 0.25)',
                // }}
                mb={{ base: '20px', md: '0' }}
                position="relative"
              >
                <Box
                  bgImage={`url(${config.imgPath}/d/46272/1681964046-icon.svg)`}
                  bgSize="cover"
                  width={{ base: '66.67px', md: '103.16px' }}
                  height={{ base: '66.67px', md: '103.16px' }}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  onClick={onOpen}
                  cursor="pointer"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                ></Box>
                {/* <LazyLoadImageComponent
                  src={embedContent?.image?.url}
                  h="100%"
                  objectFit="cover"
                  minH={{ base: '290px', md: '433px', lg: '433px' }}
                  w="100%"
                  // onClick={e=>setVidVis(!vidVis)}
                  onClick={onOpen}
                /> */}
                <LazyLoadImageComponent
                  src={embedContent?.image?.url}
                  h="100%"
                  objectFit="cover"
                  loading="eager"
                  alt={embedContent?.image?.alt || 'Divine feminine'}
                  minH={{ base: '270px', md: '410px' }}
                  maxH={{ base: '290px', md: '433px', lg: '433px' }}
                  w="100%"
                  // onClick={e=>setVidVis(!vidVis)}
                />
                {/* <MediaEmbed
                  sectionContent={embedContent}
                  bg="transparent"
                  // h="100%"
                /> */}
              </Box>
              <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
                <ModalOverlay bg="rgba(0, 0, 0, 0.7)" />
                <ModalContent
                  p={0}
                  m="0"
                  bg={{ base: '#000000' }}
                  // transform={{ base: 'rotate(270deg)', md: 'none' }}
                  maxW={{ base: 'auto', md: '992px' }}
                  maxH={{ base: '357px', md: '587px' }}
                >
                  <ModalCloseButton
                    _hover={{ bg: '#fff', color: '#000' }}
                    _active={{ bg: '#fff', color: '#000' }}
                    _focus={{ bg: '#fff', color: '#000' }}
                    zIndex="20"
                    top="-5px"
                    bg="#fff"
                    color="#000"
                    right="-5px"
                    rounded="full"
                    className={styles.closeButton}
                  />
                  <ModalBody
                    p={0}
                    m="0"
                    w="100%"
                    position="relative"
                    height="100%"
                  >
                    <Box
                      pos={{ base: 'static', md: 'static' }}
                      left="50%"
                      right="50%"
                      // transform={{ md: 'none', base: 'translate(-50%, -50%)' }}
                      display={{ base: 'flex', md: 'block' }}
                      justifyContent={{ base: 'center' }}
                      alignItems={{ base: 'center' }}
                    >
                      <Box
                        bg="#FFFFFF"
                        onClick={onClose}
                        w="32px"
                        h="32px"
                        rounded="full"
                        _hover={{ bg: '#ffffff' }}
                        display={{ base: 'flex', md: 'none' }}
                        // placeItems="center"
                        justifyContent="center"
                        alignItems="center"
                        pos="absolute"
                        top="-145px"
                        left="-185px"
                        fontFamily="FedraSansStd-Book"
                        zIndex="10"
                        cursor="pointer"
                        color="black"
                      >
                        x
                      </Box>
                      <Box w="100%" height="100%">
                        <YouTube
                          videoId={getEmbedUrl(
                            embedContent?.url || embedContent?.embedUrl
                          )}
                          onReady={onReady}
                          opts={opts}
                          loading="lazy"
                          className={styles.RitualEmbed}
                        />{' '}
                      </Box>
                    </Box>
                    {/* </Box> */}
                  </ModalBody>
                </ModalContent>
              </Modal>
              <Box
                alignSelf="flex-end"
                w={{ base: '100%', md: '100%', lg: '50%' }}
              >
                <Flex
                  flexDir="column"
                  alignItems="flex-start"
                  p="0"
                  mt={{ base: '23px', md: '63px' }}
                  maxW={{ base: 'auto', md: 'auto', lg: '441px' }}
                >
                  <Text
                    as="h4"
                    fontSize={{ base: '32px', md: 'h4', lg: 'h4' }}
                    lineHeight={{ base: '44px', md: '55px', lg: '55px' }}
                    fontWeight="600"
                    maxW={{ base: '100%', xl: '343px' }}
                    color="title.dark"
                    fontFamily="FedraSerifDisplay"
                    fontStyle="normal"
                    mb={{ base: '16px', md: '16px' }}
                    mt={{ base: '25px', md: '5px' }}
                  >
                    {title?.text}
                  </Text>
                  <StructuredTextParser
                    str={render(description?.body)}
                    region={region}
                    sectionContent={description}
                    lang={lang}
                    p="0"
                    mt="-5px"
                    mb="0"
                    fontSize="18px"
                  />
                  <NextLink
                    href={refineUrlDomain(link?.linkUrl, region, lang)}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      mt={{ base: '11px', md: '16px', lg: '16px' }}
                      color="#B18678"
                      fontSize={{ base: 'body1', md: 'body1', lg: 'body1' }}
                      lineHeight={{ base: '17px', md: '17px', lg: '17px' }}
                      fontWeight="500"
                      fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                      fontStyle="normal"
                      textTransform="uppercase"
                      textDecor="underline"
                      display="flex"
                      gridGap={{ base: '16px', md: '10px' }}
                    >
                      {link?.buttonText} <BsFillArrowRightCircleFill />
                    </Link>
                  </NextLink>
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default DivineFeminine;
