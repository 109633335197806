/* Built In Imports */
import NextLink from 'next/link';
import React from 'react';

/* External Imports */
import { Box, Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import parser from 'html-react-parser';

/* Internal Imports */
/* Components */
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
/* Services */

/**
 * Render SliderCard Component
 *
 * @param {object} tab - section data
 * @param {string} region
 * @param {string} lang
 * @param {string} pType
 * @param {object} section
 * @returns {ReactElement} SliderCard
 */

const SliderCard3 = ({ tab, region, lang, pType, section }) => {
  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      py={{ base: '40px', md: '0' }}
      pb={pType == 'home' && section.viewH ? '0px' : '50px'}
    >
      <Box display={{ base: 'block', md: 'none' }} pt="15px" px="16px">
        <Text
          fontFamily="fedraSerifDisplay"
          fontStyle="normal"
          as="h3"
          fontSize={{ base: 'h5', md: 'h2' }}
          fontWeight="600"
          lineHeight={{ base: '49px', md: '86px' }}
          mb={{ base: '10px', md: '10px' }}
          color="#000"
        >
          {tab?.content[0]?.text}
        </Text>
      </Box>
      <Flex
        flexDir={{
          base: 'column-reverse',
          md: 'row',
        }}
        w="100%"
        h={{ base: 'auto', md: '100vh' }}
        pos="relative"
        zIndex={2}
        alignItems="center"
        alignContent="center"
        justifyContent={{ base: 'space-around', md: 'space-between' }}
        padding={{ base: '0 17px', md: '4%', lg: '4% 8%' }}
      >
        <Flex
          width={{ base: '100%', md: '50%' }}
          alignItems="center"
          justifyContent="center"
        >
          <Box maxW={{ base: '100%', md: '90%', xl: '463px' }}>
            <Text
              fontFamily="fedraSerifDisplay"
              fontStyle="normal"
              as="h3"
              fontSize={{ base: 'h5', md: 'h2' }}
              fontWeight="600"
              lineHeight={{ base: '49px', md: '86px' }}
              mb={{ base: '10px', md: '10px' }}
              color="#000"
              display={{ base: 'none', md: 'block' }}
            >
              {tab?.content[0]?.text}
            </Text>

            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body2', md: 'body1' }}
              fontWeight={{ base: '350', md: '350' }}
              lineHeight={{ base: '26px', md: '28.44px' }}
              mb={{ base: '30px', md: '40px' }}
              color="#8D7452"
              display={{ base: 'none', md: 'block' }}
            >
              {tab?.content[1]?.text}
            </Text>
            <Box
              mt={{ base: '18px', md: '40px' }}
              display="flex"
              alignItems={{ base: 'center', md: 'start' }}
              justifyContent={{ base: 'center', md: 'start' }}
            >
              <SimpleButton
                style={tab?.content[2]}
                region={region}
                lang={lang}
              />
            </Box>
          </Box>
        </Flex>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w={{ base: '100%', md: '50%' }}
          maxW={562}
          h={{ base: 'auto', md: 'auto', lg: 'auto' }}
          bg="#fff"
          mt={{ base: '20px', md: '0' }}
          mb={{ base: '20px', md: '0' }}
        >
          <Flex
            alignItems="center"
            justifyContent="space-around"
            flexDir="column"
            w="100%"
            px={{ base: '20px', md: '45px' }}
            h="auto"
          >
            {React.Children.toArray(
              tab?.content[3]?.cards?.map((card, index) => {
                return (
                  <Flex
                    key={index}
                    borderBottom={
                      index === tab?.content[3]?.cards.length - 1
                        ? 'none'
                        : '0.885935px solid #CFCACA'
                    }
                    pt={{
                      base: '15px',
                      md: '35px',
                    }}
                    pb={{
                      base: '15px',
                      md: '35px',
                    }}
                    gridGap={{ base: '20px', md: '37px' }}
                    w="100%"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Box>
                      <Image
                        src={card?.thumbnail?.url}
                        alt={card?.thumbnail?.alt||""}
                        h="117px"
                        w="146px"
                        objectFit="cover"
                      />
                    </Box>
                    <Flex
                      // flex={1}
                      alignItems="flex-strart"
                      justifyContent="space-around"
                      flexDir="column"
                    >
                      <Text
                        fontStyle="normal"
                        fontWeight="bold"
                        fontSize={{ base: 'body2', md: '20px' }}
                        lineHeight="27px"
                        color="#000"
                      >
                        {card?.title}
                      </Text>
                      <Text
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize={{ base: '12px', md: 'body2' }}
                        lineHeight={{ base: '16px', md: '21px' }}
                        color="#8D7452"
                        maxW={216}
                      >
                        {parser(render(card?.description))}
                      </Text>
                      <NextLink
                        href={refineUrlDomain(card?.buttonLink, region, lang)}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          textDecor="none"
                          _hover={{ textDecoration: 'none' }}
                        >
                          <Button
                            variant="secondary"
                            w="80px"
                            maxW="auto"
                            mt={{ base: '16px', md: '28px' }}
                          >
                            {card?.buttonText}
                          </Button>
                        </Link>
                      </NextLink>
                    </Flex>
                  </Flex>
                );
              })
            )}
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default SliderCard3;
