/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import config from '@config';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
import styles from '@lb/Sections/lbHomePage/HomeTopBanner.module.css';
/* Services */

/**
 * Render SliderCard Component
 *
 * @param {object} tab - section data
 * @param {string} region
 * @param {string} lang
 * @param {string} pType
 * @param {object} section
 * @returns {ReactElement} SliderCard
 */

const SliderCard2 = ({ tab, region, lang, pType, section }) => {
  return (
    <Box
      h="100%"
      w="100%"
      position="relative"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      py={{ base: '50px', md: '0' }}
      pb={pType == 'home' && section.viewH ? '0px' : '50px'}
    >
      <Box display={{ base: 'block', md: 'none' }} pt="15px" px="16px">
        <Text
          fontFamily="fedraSerifDisplay"
          fontStyle="normal"
          as="h3"
          fontSize={{ base: 'h5', md: 'h2' }}
          fontWeight="600"
          lineHeight={{ base: '49px', md: '86px' }}
          mb={{ base: '10px', md: '10px' }}
          color="#000"
        >
          {tab?.content[0]?.text}
        </Text>
      </Box>
      <Flex
        flexDir={{
          base: 'column-reverse',
          md: 'row',
        }}
        w="100%"
        h={{ base: 'auto', md: '100vh' }}
        pos="relative"
        zIndex={2}
        alignItems="center"
        alignContent="center"
        justifyContent={{ base: 'space-around', md: 'center' }}
        padding={{ base: '0 17px', md: '5%', lg: '8%' }}
      >
        <Flex
          width={{ base: '100%', md: '50%', lg: '50%' }}
          alignItems="flex-start"
          justifyContent="flex-start"
          pt={{
            base: pType == 'home' && section.viewH ? '0px' : '18.21px',
            md: '0',
          }}
        >
          <Box maxW={{ base: '100%', md: '90%', lg: '363px', xl: '463px' }}>
            <Text
              fontFamily="fedraSerifDisplay"
              fontStyle="normal"
              fontSize={{ base: 'h5', md: 'h2' }}
              fontWeight="600"
              as="h3"
              lineHeight={{ base: '49px', md: '86px' }}
              mb={{ base: '10px', md: '10px' }}
              color="#000"
              display={{ base: 'none', md: 'block' }}
            >
              {tab?.content[0]?.text}
            </Text>

            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body2', md: 'body1' }}
              fontWeight={{ base: '350', md: '350' }}
              lineHeight={{ base: '26px', md: '28.44px' }}
              mb={{ base: '30px', md: '40px' }}
              color="#8D7452"
              display={{ base: 'none', md: 'block' }}
            >
              {tab?.content[1]?.text}
            </Text>

            <Box
              mt={{ base: '18px', md: '40px' }}
              display="flex"
              alignItems={{ base: 'center', md: 'start' }}
              justifyContent={{ base: 'center', md: 'start' }}
            >
              <SimpleButton
                style={tab?.content[3]}
                region={region}
                lang={lang}
                mb={pType == 'home' && section.viewH ? '0px' : '30px'}
              />
            </Box>
          </Box>
        </Flex>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          w={{ base: '100%', md: '50%', lg: '50%' }}
          h={{ base: 'auto', md: 'auto', lg: 'auto' }}
          p="5%"
          bg="#fff"
          mt={{ base: '5px', md: '0' }}
          mb={{ base: '5px', md: '0' }}
        >
          <Flex
            flexDir="column"
            gridGap={{ md: '30px' }}
            justifyContent="space-around"
            alignItems="center"
          >
            <Box
              alignSelf="center"
              w={{ md: '38px' }}
              h={{ md: '28px' }}
              backgroundSize="cover"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundImage={`url(${config.imgPath}/d/46272/1681740031-.png)`}
            ></Box>
            <Text
              fontStyle="normal"
              fontWeight="350"
              fontSize={{ base: 'body2', md: 'body1' }}
              lineHeight={{ base: '26px', md: '30px' }}
              color={{ base: '#49423B', md: '#000000' }}
            >
              <StructuredTextParser
                p="0"
                px="0"
                mt="0"
                className={styles.bringBhairaviText}
                str={render(tab?.content[2].body)}
              />
            </Text>
            <Flex
              // my={{ base: '11px', md: '28px' }}
              alignItems="center"
              gridGap={{ base: '11.89px', md: '21px' }}
            >
              <Image
                w="107px"
                h="107px"
                rounded="full"
                alt={tab?.content[5]?.image?.url||""}
                src={tab?.content[5]?.image?.url}
              />

              <Text
                fontStyle="normal"
                fontWeight="500"
                fontSize={{ base: '16px', md: '20.7514px' }}
                lineHeight="30px"
                color="#000000"
              >
                {tab?.content[6]?.text}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};
export default SliderCard2;
