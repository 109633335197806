/* Built In Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import Slider from 'react-slick';

/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';
import SectionTitle from '@lb/components/Headings/SectionTitle';
import { SliderCard } from '@lb/components/Sliders/SliderCard';

/* Services */
/* Styles */
// import styles from '@lb/components/Sliders/TestimonialsGroup.module.css';
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';

/**
 * Renders the TestimonialsGroupV2 component.
 *
 * @param {object} testimonialsObj component.
 * @param testimonialsObj.sectionContent
 * @param {string} region - Region of page
 * @param {string} lang - Language of page
 * @param testimonialsObj.myRef
 * @returns {ReactElement} TestimonialsGroupV2 component.
 */

const TestimonialsGroupV2 = ({ section }) => {
  const sectionContent = section?.sectionContent[0];
  const SampleNextArrow = props => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        pos="absolute"
        textAlign={'center'}
        top={{ base: '100%', sm: '98%', md: '99%', lg: '85%' }}
        zIndex="10"
        cursor="pointer"
        border="0"
        borderRadius="100%"
        w="54px"
        h="54px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        left={{ base: '70px', sm: '80px', md: '39%', xl: '35%' }}
        right={{ base: 'none', sm: 'none', md: '90%', xl: '65%' }}
        bgSize="13px"
        _before={{ display: 'none' }}
        _after={{ display: 'none' }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.166 11.001L23.166 17.001M23.166 17.001L17.166 23.001M23.166 17.001H11.166M2.16601 17.001C2.16601 25.2852 8.88181 32.001 17.166 32.001C25.4503 32.001 32.166 25.2852 32.166 17.001C32.166 8.7167 25.4503 2.00098 17.166 2.00098C8.88181 2.00098 2.16601 8.7167 2.16601 17.001Z"
            stroke="#121212"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const SamplePrevArrow = props => {
    const { className, onClick } = props;
    return (
      <Box
        className={className}
        onClick={onClick}
        display="flex"
        justifyContent="center"
        alignItems="center"
        pos="absolute"
        textAlign={'center'}
        top={{ base: '100%', sm: '98%', md: '99%', lg: '85%' }}
        zIndex="10"
        cursor="pointer"
        border="0"
        borderRadius="100%"
        w="54px"
        _before={{ display: 'none' }}
        _after={{ display: 'none' }}
        h="54px"
        left={{ base: '0', sm: '0%', md: '0%', xl: '30.5%' }}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.416 11.001L11.416 17.001M11.416 17.001L17.416 23.001M11.416 17.001H23.416M32.416 17.001C32.416 25.2852 25.7002 32.001 17.416 32.001C9.13174 32.001 2.41602 25.2852 2.41602 17.001C2.41602 8.7167 9.13174 2.00098 17.416 2.00098C25.7002 2.00098 32.416 8.7167 32.416 17.001Z"
            stroke="#121212"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Box>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30px 20px',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.15,
          slidesToScroll: 1,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          centerPadding: '10px',
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          centerPadding: '10px',
        }
      }
    ]
  };
  const router = useRouter();
  let currUrl = router.asPath;

  return (
    <>
      <Box className={'testimonial'} pos="relative" maxW="1330px" m="0 auto" mb="20px">
        {currUrl.includes('rituals') && (
          <Box
            className={'bottomGradient'}
            width="100%"
            h={{ base: '141px', md: '234px' }}
            position="absolute"
            // bottom={0}
            top={{base:'20px', md:'0'}}
            left="0"
            right="0"
            bg="linear-gradient(180deg, #D5CAAE 0%, rgba(255, 255, 255, 0) 100%)"
            transform="matrix(1, 0, 0, -1, 0, 0)"
          ></Box>
        )}
        {sectionContent?.title.length > 0 && (
          <Box
            display="block"
            width="100%"
            mb="86px"
            mx={{ base: '16px', md: 'auto' }}
          >
            <SectionTitle titleObj={sectionContent.title[0]} />
          </Box>
        )}

        <Box
          display="block"
          width="100%"
          position="relative"
          // ? add a smaller image, commenting for now
          bgImage={`url(${config.imgPath}/d/46272/1684471597-slidecontainerbg.webp)`}
          height={{ base: '582px', sm: '582px', md: '750px', lg: '627px' }}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="top"
          mt={{ base: '98.08px', md: '126px' }}
          // maxW="1071px"
        >
          <Box
            pos="absolute"
            top="-66px"
            right="0"
            left="0"
            w={{ base: '90%', md: '100%' }}
            padding={{ base: '0px 0 40px', md: '55px 0px  0 0px' }}
            height={{ base: '582px', sm: '520px', md: '735px', lg: '593px' }}
            // height="auto"
            maxW="1071px"
            maxH="auto"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            className={'cardContainer'}
            _after={{
              content: "''",
              backgroundImage: `url(${config.imgPath}/d/46272/1684471931-bottomimage.svg)`,
              width: {base:'119px', md:'160px'},
              height: {base:'119px', md:'160px'},
              backgroundSize: {base:'cover', md:'contain'},
              backgroundPosition: 'center',
              position: 'absolute',
              bottom: {base:'-40px', md:'-54px'},
              right: {base:'0px', md:'40px'},
            }}
            backgroundPosition="center"
            m="auto"
            backgroundImage={`url(${config.imgPath}/d/46272/1684471838-sliderbg.svg)`}
          >
            <Box
              pos="relative"
              w="100%"
              h="100%"
              className={`sliderContainer `}
            >
              <Slider {...settings} style={{ height: '100%', width: '100%' }} className="lbtestimonial">
                {sectionContent?.testimonials.map(testimonial => {
                  return (
                    <SliderCard key={nanoid()} testimonial={testimonial} ptype="lb" />
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </Box>
        <style global jsx>
          {`
          .lbtestimonial {
            padding: 10px 0px 20px 16px;
          }
          @media (max-width: 768px) {
            .lbtestimonial {
              padding: 30px 0px 20px 16px;
            }
        }
        `}
        </style>
      </Box>
    </>
  );
};

export default TestimonialsGroupV2;
