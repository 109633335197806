/* Built In Imports */
import React from 'react';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import SimpleButton from '@lb/components/Buttons/SimpleButton';

/* Styles */
/* Services */

/**
 * Render SliderCard Component
 *
 * @param {object} tab - section data
 * @param {string} region
 * @param {string} lang
 * @param {string} pType
 * @param {object} section
 * @returns {ReactElement} SliderCard
 */

const SliderCard1 = ({ tab, region, lang, section, pType }) => {
  return (
    <Box
      h={pType == 'home' && section.viewH ? '100svh' : 'auto'}
      w="100%"
      position="relative"
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      py={{ base: '50px', md: '0' }}
    >
      <Box display={{ base: 'block', md: 'none' }} pt="15px" px="16px">
        <Text
          fontFamily="fedraSerifDisplay"
          fontStyle="normal"
          as="h3"
          fontSize={{ base: 'h5', md: 'h2' }}
          fontWeight="600"
          lineHeight={{ base: '49px', md: '86px' }}
          mb={{ base: '10px', md: '10px' }}
          color="#000"
        >
          {tab?.content[0]?.text}
        </Text>
      </Box>
      <Flex
        flexDir={{
          base: 'column-reverse',
          md: 'row',
        }}
        w="100%"
        h={{ base: 'auto', md: '100%' }}
        pos="relative"
        zIndex={2}
        alignItems="center"
        alignContent="center"
        justifyContent={{ base: 'space-around', md: 'space-between' }}
        p={{ base: '0 17px', md: '4%', lg: '8%' }}
      >
        <Flex
          width={{ base: '100%', md: '100%', lg: '50%' }}
          alignItems="flex-start"
          justifyContent="flex-start"
          pt={{ base: '18.21px', md: '0' }}
        >
          <Box maxW={{ base: '100%', md: '90%', xl: '463px' }}>
            <Text
              fontFamily="fedraSerifDisplay"
              fontStyle="normal"
              fontSize={{ base: 'h5', md: 'h2' }}
              as="h3"
              fontWeight="600"
              lineHeight={{ base: '49px', md: '86px' }}
              mb={{ base: '10px', md: '10px' }}
              color="#000"
              display={{ base: 'none', md: 'block' }}
            >
              {tab?.content[0]?.text}
            </Text>

            <Text
              fontFamily="'FedraSerifAStdBook'"
              fontSize={{ base: 'body2', md: 'body1' }}
              fontWeight={{ base: '350', md: '350' }}
              lineHeight={{ base: '26px', md: '28.44px' }}
              mb={{ base: '30px', md: '40px' }}
              color="#8D7452"
            >
              {tab?.content[1]?.text}
            </Text>
            <Box
              mt={{ base: '18px', md: '40px' }}
              display="flex"
              alignItems={{ base: 'center', md: 'start' }}
              justifyContent={{ base: 'center', md: 'start' }}
            >
              <SimpleButton
                style={tab?.content[3]}
                region={region}
                lang={lang}
              />
            </Box>
          </Box>
        </Flex>
        <Box
          // // display="grid"
          // placeItems="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          w={{ base: '80%', sm: '90%', md: '80%', lg: '50%' }}
          h={{ base: 'auto', md: 'auto', lg: 'auto' }}
          minH={{ base: '200px' }}
          p="5%"
          bg="#fff"
          mt={{ base: '0', md: '0' }}
          mb={{ base: '20px', md: '0' }}
        >
          <LazyLoadImageComponent
            width="100%"
            // minH={{ base: '342px', md: '342px', lg: '100%' }}
            maxH={{ base: '342px', md: '342px', lg: '100%' }}
            h="100%"
            alt={tab?.content[2]?.image?.alt}
            src={tab?.content[2]?.image?.url}
          />
        </Box>
      </Flex>
    </Box>
  );
};
export default SliderCard1;
