/* Built In Imports */

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import CommonComponents from '@lb/Common/CommonComponents';
import StyleConfig from '@lb/Common/StyleConfig';
import BlogHeader from '@lb/Sections/BlogHeader';
import RitualFilters from '@lb/Sections/Rituals/RitualFilters';
import TwoImageGallery from '@lb/Sections/TwoImageGallery';
import BringBhairaviHomeSlider from '@lb/Sections/lbHomePage/BBSlider/BringBhairaviHomeSlider';
import DivineFeminine from '@lb/Sections/lbHomePage/DivineFeminine';
import HomeBanner from '@lb/Sections/lbHomePage/HomeBanner';
import HomeFullPageCard from '@lb/Sections/lbHomePage/HomeFullPageCard';
import RelatedContentSlider from '@lb/components/Blogs/RelatedContentSlider';
import VisitBhairaviSlider from '@lb/components/Sliders/VisitBhairaviSlider';
import { FilterProvider } from '@store/lb/filterContext';
import LbTestimonial from '@lb/components/Sliders/TestimonialsGroupV2';


/**
 * Renders Render LB Sections
 *
 * @param {object} sectionContent - Data
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Render MSR Sections
 */

const RenderSections = ({
  section,
  region,
  lang,
  pType,
  csrData,
  headerMenuData,
  topMenuData,
  pageData,
  pageName,
}) => {
  const { sectionId } = section;
  if (sectionId === 'ritual-filters') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <FilterProvider>
          <RitualFilters
            section={section}
            region={region}
            lang={lang}
            pageData={pageData}
          />
        </FilterProvider>
      </StyleConfig>
    );
  } else if (sectionId === 'blog-header') {
    return (
      <StyleConfig
        id={section.sectionId}
        key={section.sectionId}
        pT="0"
        pB="0"
        mB="0"
        section={section}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        minH={{ base: 'auto', lg: '600px' }}
      >
        <BlogHeader section={section} region={region} lang={lang} />
      </StyleConfig>
    );
  } else if (sectionId === 'home-banner') {
    return (
      <>
        <HomeBanner
          section={section}
          region={region}
          lang={lang}
          headerMenuData={headerMenuData}
          topMenuData={topMenuData}
        />
      </>
    );
  } else if (sectionId === 'divine-feminine') {
    return (
      <>
        <DivineFeminine
          section={section}
          region={region}
          lang={lang}
          pType={pType}
        />
      </>
    );
  } else if (sectionId === 'bring-bhairavi-home') {
    return (
      <>
        <BringBhairaviHomeSlider
          section={section}
          region={region}
          lang={lang}
          pType={pType}
        />
      </>
    );
  } else if (sectionId === 'homepage-blog-list') {
    return (
      <RelatedContentSlider
        region={region}
        lang={lang}
        style={sectionId}
        section={section}
        sectionContent={section.sectionContent[0]?.tabs[0]?.cards}
        title={section.sectionContent?.title}
        subTitle={section.sectionContent[0]?.tabs[0]?.title}
      />
    );
  } else if (
    sectionId === 'visit-bhairavi-1' ||
    sectionId === 'visit-bhairavi-3' ||
    sectionId === 'visit-bhairavi-2'
  ) {
    return (
      <>
        <VisitBhairaviSlider
          section={section}
          region={region}
          lang={lang}
          sectionId={sectionId}
        />
      </>
    );
  }
  else if (sectionId === 'help-devi') {
    return (
      <>
        <HomeFullPageCard section={section} region={region} lang={lang} />
      </>
    );
  } else if (sectionId === '2-image-gallery') {
    return (
      <>
        <TwoImageGallery section={section} region={region} lang={lang} />
      </>
    );
  } else if (sectionId === 'lb-testimonial') {
    return (
      <>
        <LbTestimonial section={section} region={region} lang={lang} />
      </>
    );
  } else {
    return (
      <FilterProvider>
        <Box as="div" display="block" placeContent="center">
          {section?.sectionContent && section?.sectionContent?.length
            ? section?.sectionContent?.map((secCon, i) => {
                return (
                  <StyleConfig
                    id={section?.sectionId}
                    key={section?.sectionId}
                    style={section?.styleConfig}
                    section={section}
                    // pB={{ base: '0', md: '15px' }}
                  >
                    <CommonComponents
                      key={nanoid()}
                      sectionContent={secCon}
                      section={section}
                      region={region}
                      language={lang}
                      pType={pType}
                      csrData={csrData}
                      pageData={pageData}
                      index={i}
                      pageName={pageName}
                    />
                  </StyleConfig>
                );
              })
            : null}
        </Box>
      </FilterProvider>
    );
  }
};

export default RenderSections;
