/* Built In Imports */
import NextLink from 'next/link';
import React, { useEffect, useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, Text, useBreakpointValue } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import config from '@config';
import LBHeader from '@lb/components/Header/LBHeader';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
import styles from '@lb/Sections/lbHomePage/HomeTopBanner.module.css';

/* Services */

const HomeTopBanner = ({
  sliderContent,
  title,
  description,
  images,
  video,
  region,
  lang,
  headerMenuData,
  topMenuData,
  section,
  mobileVideo,
}) => {
  const [slider, setIsSlider] = useState(true);
  const [navigatorObj, setNavigatorObj] = useState(true);
  let iOS = false;

  const iPhoneScreenHide = () => {
    var elVideo = document.getElementById('hideiOSVideo');
    iOS = /iPad|iPhone|iPod/.test(navigator?.userAgent) && !window.MSStream;
    console.log('iOS', iOS, navigator.userAgent);
    setNavigatorObj(navigator.userAgent)
    if (iOS) {
      elVideo.style.display = 'none';
    }
  };
  useEffect(() => {
    iPhoneScreenHide();
  }, []);

  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => (
      <Box
        style={{
          borderRadius: '10px',
          // padding: '10px',
          position: 'absolute',
          // bottom: '90%',
          right: 0,
          width: '70px',
          display: 'flex',
        }}
        top={{ base: '50px', md: '9px' }}
        left={{
          base: '50%',
          sm: '50%',
          md: '-78px',
          lg: '-58px',
        }}
        className={styles.homeBannerSlickDot}
      >
        <ul style={{ margin: '0px' }} className={styles.homeBannerSlickDot}>
          {' '}
          {dots}{' '}
        </ul>
      </Box>
    ),
    customPaging: i => (
      <div
        style={{
          width: '8px',
          height: '8px',
          color: 'transparent',
          borderRadius: '50%',
        }}
        className="dot"
      >
        {i + 1}
      </div>
    ),
  };

  return (
    <Box>
      <Box bgColor="#fff">
        <LBHeader
          headerMenuData={headerMenuData}
          topMenuData={topMenuData}
          region={region}
          language={lang}
        />
      </Box>
      <Box
        className="homeBanner"
        bg={{ base: '#300303', md: 'transparent' }}
        position={'relative'}
      >
        <Box
          height={{ base: 'calc(100svh - 74px)', xl: 'calc(100svh - 160px)' }}
          // height={{
          //   base: `${section.viewH ? 'calc(100svh - 74px)' : 'auto'}`,
          //   md: '100%',
          // }}
          minHeight="650px"
          backgroundSize="cover"
          backgroundImage={{
            base: `url(${images?.mobileImage?.url})`,
            md: `url(${images?.image?.url})`,
          }}
          backgroundPosition="top"
        >
          <Box class="bkg" id="hideiOSVideo">
            <Box display={{ base: 'block', md: 'none' }}>
              <video className="vid" autoPlay muted loop>
                <source src={mobileVideo?.text} type="video/mp4" />
              </video>
            </Box>
            <Box display={{ base: 'none', md: 'block' }}>
              <video className="vid" autoPlay muted loop>
                <source src={video?.text} type="video/mp4" />
              </video>
            </Box>
          </Box>
          <Box
            pos="absolute"
            top={{ base: '50%', md: 0 }}
            bottom={{ base: '0', md: '-32px' }}
            left="0"
            backgroundPosition={{ base: '0 -99px', md: 'center' }}
            bg={{
              base: 'linear-gradient(0deg, #300303 60%, rgba(88, 0, 0, 0) 92.88%)',
              md: 'linear-gradient(90deg, rgba(101, 0, 15, 0.6) 36%, rgba(217, 217, 217, 0) 91.62%)',
            }}
            w={{ base: '100%', md: '60%' }}
            h={{ base: '50%', md: '100%' }}
            display="flex"
            justifyContent={{ base: 'flex-start', md: 'center' }}
            alignItems={{ base: 'flex-end', md: 'center' }}
            px={{ base: '7%', md: '0px' }}
            py={{ base: '41px', md: '0' }}
          >
            <Flex flexDir="column" w="100%" maxW={{ base: 'auto', md: 401 }}>
              <Text
                fontSize={{ base: 'h5', md: 'h4', lg: 'h1' }}
                as="h1"
                lineHeight={{ base: '52px', md: '', lg: '110px' }}
                fontFamily="FedraSerifDisplay"
                fontStyle=""
                color="title.light"
                fontWeight={600}
              >
                {title?.text}
              </Text>
              <Text
                fontSize={{ base: 'body2', md: 'body1', lg: 'body1' }}
                as="p"
                lineHeight={{ base: '26px', md: '29px', lg: '29px' }}
                fontStyle="normal"
                fontWeight={350}
                color="bodyText.color3"
                pos="relative"
                _before={{
                  content: '""',
                  width: useBreakpointValue({
                    base: '22px !important',
                    md: '45px',
                  }),
                  backgroundImage: `url(${config.staticPath}/d/46272/1684435199-homequote.png)`,
                  height: useBreakpointValue({ base: '16px', md: '36px' }),
                  position: 'absolute',
                  left: useBreakpointValue({ base: '-24px', md: '-65px' }),
                  backgroundSize: 'contain',
                }}
              >
                {description?.text}
              </Text>
            </Flex>
          </Box>
          {slider && (
            <Flex
              w="100%"
              h="auto"
              alignItems={{ base: 'flex-start', md: 'center' }}
              justifyContent={{ base: 'flex-start', md: 'center' }}
              backgroundColor="rgba(0, 0, 0, 0.6)"
              pos="absolute"
              top="0"
            >
              <Box
                className={styles.HomeSliderContainer}
                w={{
                  base: '100%',
                  sm: '100%',
                  md: '40%',
                  lg: '33%',
                }}
                py="14.5px"
                h="auto"
              >
                <Slider {...settings}>
                  {/* {Object.keys(sliderContent?.data)?.map((item, index) => { */}
                  {sliderContent?.cards?.map((item) => {
                    return (
                      <Box
                        w={{ base: '300px', md: '480px' }}
                        px={{ base: '0', md: '12px' }}
                        style={{ height: '19px' }}
                        key={nanoid()}
                        display="flex"
                        m={{ base: '0 14px', md: '0 10px' }}
                        alignItems="center"
                      >
                        <NextLink
                          href={refineUrlDomain(item?.linkUrl, region, lang)}
                          passHref
                          target={
                            item?.linkUrl?.indexOf('http') > -1 &&
                              item?.linkUrl?.indexOf(`${config.cdnPath}/linga-bhairavi`) < 0
                              ? '_blank'
                              : ''
                          }
                          legacyBehavior>
                          <Link
                            target={
                              item?.linkUrl?.indexOf('http') > -1 &&
                                item?.linkUrl?.indexOf(`${config.cdnPath}/linga-bhairavi`) < 0
                                ? '_blank'
                                : ''
                            }
                          >
                            <Box
                              fontSize={{ base: 'body1', md: 'body1' }}
                              lineHeight="19px"
                              fontStyle="normal"
                              fontWeight="500"
                              letterSpacing="0.025em"
                              fontFeatureSettings="'case' on"
                              color="title.light"
                            >
                              <Box
                                mr={{ base: '8px', md: '14px' }}
                                w={{ base: 'max(270px, 50%)', md: 'auto' }}
                                display="flex"
                                gridGap="10px"
                                flexFlow=" wrap"
                              >

                                <Text
                                  fontWeight="500"
                                  textDecorationLine="underline"
                                  fontFamily="FedraSansStd-A-medium"
                                  display={{
                                    base: 'block',
                                    lg: 'inline-block',
                                  }}
                                >
                                  {item?.buttonText}{' '}
                                </Text>{' '}
                                {item?.subtextDate}
                                {/* <BsArrowRight style={{ color: '#F6A15E' }} /> */}
                              </Box>
                            </Box>
                          </Link>
                        </NextLink>
                      </Box>
                    );
                  })}
                </Slider>
              </Box>
              <Box
                pos="absolute"
                right={{ base: '2%', md: '10%' }}
                top={{ base: '20px', md: '1px' }}
                w="40px"
                h="100%"
                alignSelf="flex-end !important"
                className="pinky"
                color="#fff"
                textAlign="center"
                zIndex={5}
                transform="translate(0px, 10px)"
              >
                <CloseIcon
                  cursor="pointer"
                  onClick={() => {
                    setIsSlider(false);
                  }}
                />
              </Box>
            </Flex>
          )}
        </Box>
        <style>
          {`
          .slick-dots .slick-active div{
            background:#F6A15E !important;
          }
          .bkg {
            display: flex;
            align-items: stretch;
            justify-content: center;
            height:calc(100vh - 160px);
            min-height:650px;
            width: 100%;
            overflow: hidden;
            margin: 0 auto;
          }

          .vid {
            display: block;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height:calc(100vh - 160px);
            min-height:650px
          }
          @media(max-width:767px) {
            .bkg, .vid { 
              height:calc(100vh - 74px);
            }
          }
          @media(max-width:1023px) {
            .bkg, .vid { 
              height:calc(100vh - 74px);
            }
          }
          `}
        </style>
      </Box>
    </Box>
  );
};

export default HomeTopBanner;
