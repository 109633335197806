/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';



/* Internal Imports */
/* Components */
import LingaBhairaviCard from '@lb/components/Cards/LingaBhairaviCard';
import LingaBhairaviFilter from '@lb/components/Filter/LingaBhairaviFilter';

/* Styles */
import { useFilterContext } from '@store/lb/filterContext';
/* Services */

const RitualFilters = ({ section, region, lang, pageData }) => {
  let { blocksVis, setBlocksVis } = useFilterContext();

  return (
    <Box>
      <LingaBhairaviFilter
        sectionContent={section?.sectionContent[0]}
        section={section}
        pageData={pageData}
      />
      {section?.sectionContent.map((sectionContent, index) => {
        return (
          index > 0 && (
            <>
              <LingaBhairaviCard
                sectionContent={sectionContent}
                region={region}
                lang={lang}
                key={nanoid()}
                pageData={pageData}
              />
            </>
          )
        );
      })}

      {!blocksVis && (
        <Box
          w="100%"
          maxW="1330px"
          margin={{ base: '30px auto 184px auto', md: '30px auto 621px auto' }}
        >
          <Box
            maxW={1200}
            mx="auto"
            width="100%"
            pl={{ base: '17px', md: '0' }}
            pos="relative"
            zIndex="1"
            pb={{
              base: '15px',
              md: '25px',
            }}
          >
            <Text
              color="title.dark"
              fontSize={{ base: '28px', md: 'h5' }}
              fontFamily="fedraSerifDisplay"
              fontWeight={600}
              lineHeight={{ base: '30px', md: '49.22px' }}
            >
              Search Results (0)
            </Text>
          </Box>
          <Box
            w="100%"
            minHeight={{ base: '375px', md: '430px' }}
            bg={
              'linear-gradient(180deg, rgba(212, 195, 162, 0.8) 0%, rgba(217, 217, 217, 0) 60.56%)'
            }
            backgroundSize="100%"
          >
            <Flex
              flexWrap="wrap"
              justifyContent={{
                base: 'center',
                lg: 'center',
                xl: 'space-between',
              }}
              maxW="1208px"
              alignItems="flex-start"
              alignContent="flex-start"
              margin="0 auto"
              w="auto"
              gridGap="16px"
              pos="relative"
              pt="30px"
              pl="10px"
            >
              Sorry, no results were found. Please change the search criteria to
              see more rituals.
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RitualFilters;
