/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';

/* Styles */
/* Services */
/**
 *
 * @param {object} section
 * @param region
 * @param language
 */

const TwoImageGallery = ({ sectionContent, section, region, lang }) => {

  return (
    <Box
      maxW="1330px" width="100%" m="0 auto"
    >
      <Box flexDirection={{ base: 'column', md: 'row' }} h='auto' pos='relative' display={{ base: 'none', md: 'block' }}
      >
        <Flex>
          <LazyLoadImageComponent
            width={{ base: '120px', md: '647px' }}
            height={{ base: '120px', md: '437px' }}
            //padding={2}
            src={section?.sectionContent[0]?.image?.url}
          />
      
          <LazyLoadImageComponent
            width={{ base: '120px', md: '647px' }}
            height={{ base: '120px', md: '437px' }}
            //padding={2}
            src={section?.sectionContent[2]?.image?.url}
          />
        </Flex>
      
        <Box>
          <Flex marginLeft='50px' marginTop='40px' position='relative' flexDirection='row'>
            <LazyLoadImageComponent
              src="/img/LeftRight_BringHomeDevi.png"
              maxHeight={{ base: '123.54px', md: '50px' }}
            />
            <Box flexDirection='row' >
              {Object.keys(section?.sectionContent[4]?.data).map((item) => {
                return (
                  <Box key={nanoid()} marginTop='10px' marginLeft='20px' fontSize='20px'>
                    <Box color='#65000F' flexDirection='column'>
                      <strong>{item} </strong>
                    </Box>
                    <Box color='#8D7452' flexDirection='column' marginLeft='65px' fontSize='20px' marginTop='-34px' position='relative'>
                      {Object.values(section?.sectionContent[4]?.data[item])}
                    </Box>
                  </Box>
                )
              })}
            </Box> 
          </Flex>

          <Flex
            w='700px' h='auto' textAlign='center'
            flexDirection='column' flex='wrap' marginLeft='250px' fontWeight='500'
          >
            <StructuredTextParser
              str={render(section?.sectionContent[1].body)}
              region={region}
              sectionContent={sectionContent}
              lang={lang}
            />

            <StructuredTextParser
              str={render(section?.sectionContent[3].body)}
              region={region}
              sectionContent={sectionContent}
              lang={lang}
            />
          </Flex>
        </Box>

      </Box>

      <Box
        display={{ base: 'block', md: 'none' }}>
        <Flex flexDirection='column'>
          <Box lineHeight='160%'>
            <LazyLoadImageComponent
              width="100%"
              //padding={2}
              src={section?.sectionContent[0]?.image?.url}
            />
            <StructuredTextParser
              fontSize='16px'
              fontWeight='350' 
              str={render(section?.sectionContent[1].body)}
              region={region}
              sectionContent={sectionContent}
              lang={lang}
            />
          </Box>
          <Box lineHeight='160%'>
            <LazyLoadImageComponent
              width="100%"
              //padding={2}
              src={section?.sectionContent[2]?.image?.url}
            />
            <StructuredTextParser
              fontSize='16px'
              fontWeight='350' 
              str={render(section?.sectionContent[3].body)}
              region={region}
              sectionContent={sectionContent}
              lang={lang}
            />
          </Box>

        </Flex>
    
      </Box>
  
    </Box>
  );
};

export default TwoImageGallery;
